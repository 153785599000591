<template>
  <div>
    <!--      左侧菜单栏-->
    <a-col :span="4">
      <a-menu
        style="min-height: 300px"
        mode="vertical"
        :default-selected-keys="activeMenuTab"
        v-model="activeMenuTab"
        v-if="isShowMenu('agents/show')"
      >
        <a-menu-item
          v-for="item in showHasPermissionMenus"
          :key="item.routeName"
        >
          <router-link :to="{ name: item.routeName }">
            <a-icon :type="item.iconType" />
            {{ item.menuName }}
          </router-link>
        </a-menu-item>
      </a-menu>
    </a-col>
    <!--      内容-->
    <a-col :span="20">
      <div class="simiot-vertical-tab-content">
        <router-view />
      </div>
    </a-col>
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { isTopAgent } from '@/api/agent'

export default {
  name: 'AgentShowTab',
  data() {
    return {
      activeMenuTab: [],
      allMenus: [
        { routeName: 'agent_info', menuName: '账户信息', iconType: 'user', permission: 'agents/info', isTopAgentOnly: false },
        { routeName: 'agent_setting', menuName: '账户设置', iconType: 'money-collect', permission: 'agents/setting', isTopAgentOnly: true },
        { routeName: 'agent_vpdn_manage', menuName: 'VPDN分组管理', iconType: 'laptop', permission: 'agents/vpdn_manage', isTopAgentOnly: true },
        { routeName: 'agent_api_info', menuName: '开发信息', iconType: 'api', permission: 'agents/api_info', isTopAgentOnly: true },
        {
          routeName: 'agent_receive_addresses',
          menuName: '收货信息',
          iconType: 'schedule',
          permission: 'agents/receive_address',
          isTopAgentOnly: true
        },
        {
          routeName: 'agent_enterprise_qualification',
          menuName: '企业资质',
          iconType: 'file-protect',
          permission: 'agents/enterprise_qualification',
          isTopAgentOnly: false
        },
        {
          routeName: 'agent_files',
          menuName: '文件资料',
          iconType: 'file',
          permission: 'agent_files',
          isTopAgentOnly: false
        },
        {
          routeName: 'agent_customize_site',
          menuName: '站点配置',
          iconType: 'desktop',
          permission: 'agents/customize_site',
          isTopAgentOnly: true
        }
      ],
      showHasPermissionMenus: []
    }
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    var routerName = this.$route.name
    if (this.$route.name === 'agent_vpdn_manage_info') {
      routerName = 'agent_vpdn_manage'
    }
    this.activeMenuTab = [routerName]
    this.initTabs()
  },
  methods: {
    initTabs() {
      isTopAgent(this.agentId).then((res) => {
        if (res.code === 0) {
          var isTopAgent = res.data.is_top_agent

          this.fetchShowHasPermissionMenus(isTopAgent)
        }
      })
    },

    fetchShowHasPermissionMenus(isTopAgent) {
      let isIncludeActiveMenuTab = false

      this.allMenus.forEach((value, index) => {
        if (this.isShowMenu(value.permission)) {
          if (!value.isTopAgentOnly || (value.isTopAgentOnly && isTopAgent)) {
            this.showHasPermissionMenus.push(value)
          }

          if (this.activeMenuTab[0] === value.routeName) {
            isIncludeActiveMenuTab = true
          }
        }
      })

      // 如果有权限的目录中不包含activeKey，那么activeKey 等于目录中第一个路径名
      if (!isIncludeActiveMenuTab) {
        this.activeKey = this.showHasPermissionMenus[0].routeName
      }
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    }
  }
}
</script>
